.raw-material-btn-column {
  display: flex !important;
  align-items: center;
  gap: 10px;
  border: 0 !important;
  height: 55px;
}

.date-table-checkbox {
  width: 18px;
  height: 18px;
  background: transparent 0% 0% no-repeat padding-box;
  border-radius: 2px;
  border: 1px solid #000;
}
.raw-material-data {
  width: 100% !important;
  table-layout: fixed;
  overflow: hidden;
}
.raw-material-data tr:nth-child(even) {
  background-color: #ffffff !important;
}

.sub-data-table {
  border-radius: 0px;
}
.sub-data-table-wrapper::-webkit-scrollbar {
  height: 6px; 
}

.sub-data-table-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.sub-data-table-wrapper::-webkit-scrollbar-thumb {
  background: #d3d3d3;
  border-radius: 3px;
}

.sub-data-table-wrapper::-webkit-scrollbar-thumb:hover {
  background: #8a8a8a;
}
/* Dialog Styling */
.custom-dialog .MuiPaper-root {
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Container for fields */
.dialog-fields-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Wrapper for TextField with edit icon */
.textFieldWrapper {
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}

.textFieldWrapper .MuiFilledInput-root {
  background-color: rgba(0, 0, 0, 0.09) !important;
}

/* Edit Icon Styling */
.editIcon {
  position: absolute;
  right: 10px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  color: #475467;
}

/* Update Button Styling */
.custom-update-button {
  background-color: #20447c;
  color: #fff;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.approval-buttons {
  display: flex;
  gap: 10px;
}

.approve-button {
  background-color: #00ab66;
  color: #fff;
  border: 1px solid #08925a;
  border-radius: 6px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 15px;
  font-weight: 400;
  width: 80px;
}

.reject-button {
  background-color: #e31e24;
  color: #fff;
  border: 1px solid #b91015;
  border-radius: 6px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 15px;
  font-weight: 400;
  width: 80px;
}

.dialog-fields-actions {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
}


/* Make the inner table scrollable horizontally */
.sub-data-table-wrapper {
  overflow-x: auto; /* Enables horizontal scrolling */
  white-space: nowrap; /* Prevents table cells from wrapping */
  width: 100%;
  border: 1px solid #ddd; /* Optional: adds a border to separate */
}

.sub-data-table {
  min-width: max-content;
  border-collapse: collapse;
  white-space: nowrap;
}
