.procution-line-title {
  letter-spacing: 1.08px;
  color: #222d39;
  opacity: 1;
  font-size: 36px;
  font-weight: 500;
}

.production-line-scanner {
  display: block;
  width: max-content;
  background-color: #f6f6f6 !important;
  margin-left: auto;
  margin-right: auto;
  height: max-content;
  background: #f1f1ff 0% 0% no-repeat padding-box;
}

.loader {
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  text-indent: -9999em;
  animation: mulShdSpin 1.8s infinite ease;
  transform: translateZ(0);
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #6b7c99,
      1.8em -1.8em 0 0em rgba(107, 124, 153, 0.2),
      2.5em 0em 0 0em rgba(107, 124, 153, 0.2),
      1.75em 1.75em 0 0em rgba(107, 124, 153, 0.2),
      0em 2.5em 0 0em rgba(107, 124, 153, 0.2),
      -1.8em 1.8em 0 0em rgba(107, 124, 153, 0.2),
      -2.6em 0em 0 0em rgba(107, 124, 153, 0.5),
      -1.8em -1.8em 0 0em rgba(107, 124, 153, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(107, 124, 153, 0.7),
      1.8em -1.8em 0 0em #6b7c99, 2.5em 0em 0 0em rgba(107, 124, 153, 0.2),
      1.75em 1.75em 0 0em rgba(107, 124, 153, 0.2),
      0em 2.5em 0 0em rgba(107, 124, 153, 0.2),
      -1.8em 1.8em 0 0em rgba(107, 124, 153, 0.2),
      -2.6em 0em 0 0em rgba(107, 124, 153, 0.2),
      -1.8em -1.8em 0 0em rgba(107, 124, 153, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(107, 124, 153, 0.5),
      1.8em -1.8em 0 0em rgba(107, 124, 153, 0.7), 2.5em 0em 0 0em #6b7c99,
      1.75em 1.75em 0 0em rgba(107, 124, 153, 0.2),
      0em 2.5em 0 0em rgba(107, 124, 153, 0.2),
      -1.8em 1.8em 0 0em rgba(107, 124, 153, 0.2),
      -2.6em 0em 0 0em rgba(107, 124, 153, 0.2),
      -1.8em -1.8em 0 0em rgba(107, 124, 153, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(107, 124, 153, 0.2),
      1.8em -1.8em 0 0em rgba(107, 124, 153, 0.5),
      2.5em 0em 0 0em rgba(107, 124, 153, 0.7), 1.75em 1.75em 0 0em #6b7c99,
      0em 2.5em 0 0em rgba(107, 124, 153, 0.2),
      -1.8em 1.8em 0 0em rgba(107, 124, 153, 0.2),
      -2.6em 0em 0 0em rgba(107, 124, 153, 0.2),
      -1.8em -1.8em 0 0em rgba(107, 124, 153, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(107, 124, 153, 0.2),
      1.8em -1.8em 0 0em rgba(107, 124, 153, 0.2),
      2.5em 0em 0 0em rgba(107, 124, 153, 0.5),
      1.75em 1.75em 0 0em rgba(107, 124, 153, 0.7), 0em 2.5em 0 0em #6b7c99,
      -1.8em 1.8em 0 0em rgba(107, 124, 153, 0.2),
      -2.6em 0em 0 0em rgba(107, 124, 153, 0.2),
      -1.8em -1.8em 0 0em rgba(107, 124, 153, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(107, 124, 153, 0.2),
      1.8em -1.8em 0 0em rgba(107, 124, 153, 0.2),
      2.5em 0em 0 0em rgba(107, 124, 153, 0.5),
      1.75em 1.75em 0 0em rgba(107, 124, 153, 0.5),
      0em 2.5em 0 0em rgba(107, 124, 153, 0.7), -1.8em 1.8em 0 0em #6b7c99,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(107, 124, 153, 0.2),
      1.8em -1.8em 0 0em rgba(107, 124, 153, 0.2),
      2.5em 0em 0 0em rgba(107, 124, 153, 0.2),
      1.75em 1.75em 0 0em rgba(107, 124, 153, 0.2),
      0em 2.5em 0 0em rgba(107, 124, 153, 0.5),
      -1.8em 1.8em 0 0em rgba(107, 124, 153, 0.7), -2.6em 0em 0 0em #6b7c99,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(107, 124, 153, 0.2),
      1.8em -1.8em 0 0em rgba(107, 124, 153, 0.2),
      2.5em 0em 0 0em rgba(107, 124, 153, 0.2),
      1.75em 1.75em 0 0em rgba(107, 124, 153, 0.2),
      0em 2.5em 0 0em rgba(107, 124, 153, 0.2),
      -1.8em 1.8em 0 0em rgba(107, 124, 153, 0.5),
      -2.6em 0em 0 0em rgba(107, 124, 153, 0.7), -1.8em -1.8em 0 0em #6b7c99;
  }
}

/* Stripping css */
/* Grid Layout */
.production-line-stripping {
  padding-top: 10px;
  background-color: #ffffff;
}
.selectBatchTable tr td {
  padding: 16px !important;
  font-size: 16px !important;
}

/* Batch Column */
.batch-column {
  padding: 11px 0px 20px 20px;
  /* height: 600px; */
}

.batch-column h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
}

.batch-item {
  align-items: center;
  height: 673px;
  overflow-y: auto;
}

.batch-items {
  display: flex;
  padding: 6px 0;
  background-color: #f1f1ff;
  /* border-bottom: 1px solid #e0e0e0; */
  align-items: center;
  margin-bottom: 8px;
}

.batch-item span {
  font-size: 16px;
}

/* UID Column */
.uid-column {
  padding: 10px 20px 20px 0px;
  /* height: 600px; */
}

.uid-column h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
}

.uid-item {
  font-size: 14px;
  padding: 5px 0;
  background-color: #f1f1ff;
  height: 669px;
}
.uid-items {
  text-align: left;
  height: 597px;
  overflow-y: auto;
}
.uid-items-each {
  padding: 10px 20px;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
}
.visual-inspection-feed {
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid #00ab66;
}
.visual-inspection-feed-fail {
  background-color: #ffedee;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid #e31e24;
}
.pass-btn {
  background: #e8f8f2 0% 0% no-repeat padding-box;
  border: 1px solid #00ab66;
  border-radius: 13px;
  color: #00ab66;
  padding: 5px 10px;
  font-size: 14px;
}

.fail-btn {
  background: #ffeced 0% 0% no-repeat padding-box;
  border: 1px solid #e31e24;
  border-radius: 13px;
  color: #e31e24;
  padding: 5px 30px;
  font-size: 14px;
}

.choose-defect-btn {
  background: #1c407a;
  border: 1px solid #1c407a;
  border-radius: 13px;
  color: white;
  padding: 5px 30px;
  font-size: 14px;
}
/* Assign Button */
.assign-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 40px;
  margin-top: 5px;
}

.assign-button {
  background-color: #1c407b;
  color: #fff;
  text-align: center;
  border: 1px solid #1e3760;
  border-radius: 4px;
  width: 150px;
  padding: 12px;
}
.productionline-dialog-title {
  background-color: #1c407b;
  color: #fff;
  text-align: center;
  border: 1px solid #1e3760;
  border-radius: 4px;
}
.production-line-popup-input-field .MuiFilledInput-root {
  background-color: rgba(0, 0, 0, 0.06) !important;
}
.production-line-popup-input-field
  .css-c5v1qu-MuiInputBase-input-MuiFilledInput-input.Mui-disabled {
  -webkit-text-fill-color: black !important;
}
.production-line-popup-input-field .css-fi4tqy.Mui-disabled {
  -webkit-text-fill-color: black !important;
}

.production-line-popup-input-field .css-fi4tqy {
  padding-top: 0px !important;
  height: 36px !important;
}
.production-line-popup-input-field-data
  .MuiSelect-select.MuiSelect-filled.MuiInputBase-input.MuiFilledInput-input.MuiInputBase-inputSizeSmall.css-1gzkxga-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input {
  background-color: #f0f0f0 !important;
  border-radius: 4px 4px 0px 0px !important;
}
.submit-feed-submit {
  border: 1px solid #1e3760;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  color: #fff;
  background-color: #1c407b;
  /* width: 100%; */
  padding: 10px 23px;
  font-weight: 500;
}
.disabled-data.Mui-disabled {
  color: white !important;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.pairingcard-btn-data {
  margin: 20px 10px;
  padding: 6px 30px;
}
.submit-feed-submit:disabled {
  background-color: gray;
  cursor: not-allowed;
  opacity: 0.6;
  border: none;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #d3d3d3;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.bin-Table .bin-table-td,
.bin-table-th {
  border: none !important;
  background-color: #1c407a !important;
  color: white !important;
  text-align: center !important;
}
.bin-Table {
  border: none !important;
  background-color: #1c407a !important;
  color: white !important;
  text-align: center !important;
}
.bin-Table .bin-table-tr,
.bin-table-td {
  padding: 16px !important;
  background-color: #1c407a !important;
  color: white !important;
  text-align: center !important;
}
.bin-Table .bin-table-th span > svg,
.bin-Table .bin-table-td span > svg {
  fill: #ffffff !important;
}